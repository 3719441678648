export function getModels() {
    return [
        "babbage",
        "ada",
        "text-davinci-003",
        "davinci",
        "text-embedding-ada-002",
        "babbage-code-search-code",
        "text-similarity-babbage-001",
        "text-davinci-001",
        "curie-instruct-beta",
        "babbage-code-search-text",
        "babbage-similarity",
        "curie-search-query",
        "code-davinci-002",
        "code-search-babbage-text-001",
        "code-cushman-001",
        "code-search-babbage-code-001",
        "audio-transcribe-deprecated",
        "text-ada-001",
        "text-similarity-ada-001",
        "text-davinci-insert-002",
        "ada-code-search-code",
        "text-davinci-002",
        "ada-similarity",
        "code-search-ada-text-001",
        "text-search-ada-query-001",
        "text-curie-001",
        "text-davinci-edit-001",
        "davinci-search-document",
        "ada-code-search-text",
        "text-search-ada-doc-001",
        "code-davinci-edit-001",
        "davinci-instruct-beta",
        "text-similarity-curie-001",
        "code-search-ada-code-001",
        "ada-search-query",
        "text-search-davinci-query-001",
        "davinci-search-query",
        "text-davinci-insert-001",
        "babbage-search-document",
        "ada-search-document",
        "text-search-babbage-doc-001",
        "text-search-curie-doc-001",
        "text-search-curie-query-001",
        "babbage-search-query",
        "text-babbage-001",
        "text-search-davinci-doc-001",
        "text-search-babbage-query-001",
        "curie-similarity",
        "curie-search-document",
        "curie",
        "text-similarity-davinci-001",
        "davinci-similarity",
        "gpt-3.5-turbo-0301",
        // "gpt-3.5-turbo"
    ]
}
